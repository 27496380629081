import React from "react";

import Carrier1 from "../Assets/images/Carrier1.png";
import Carrier2 from "../Assets/images/Carrier2.png";
import Carrier3 from "../Assets/images/Carrier3.png";
import Carrier4 from "../Assets/images/Carrier4.png";

function CarrierPotentials() {
  return (
    <>
      <div>
        <p className=" font-bold text-[40px] below-sm:text-[20px] leading-60 text-center">
          Explore, Learn, and Grow
        </p>
      </div>

      {/* <div className="mt-9  mx-4 md:mx-6 lg:mx-9 p-6 pt-0 flex flex-col md:flex-row md:items-stretch gap-6 lg:gap-8">
        <div className="p-4 bg-[#FE5252] flex flex-col items-start gap-4 md:gap-4 relative w-full md:w-[55%] md:h-[432px] rounded-[20px] flex-grow overflow-hidden">
          <h4
            style={{ color: "#FFFFFF" }}
            className="p-4 pb-0 text-[32px] md:text-[32px] font-bold leading-[32px] md:leading-[40px] text-center md:text-left"
          >
            Enhance Your Skills
          </h4>
          <p
            style={{ color: "#FFFFFF" }}
            className="pl-4 pr-4 text-[18px] md:text-[18px] font-normal leading-[24px] md:leading-[32px] text-start md:text-left"
          >
            Access a range of courses and resources to upskill and stay ahead in
            your field.
          </p>
          ----------- <button className="m-2 flex justify-center items-center w-[110px] h-[51px] bg-none text-[16px] md:text-[18px] font-bold leading-[16px] md:leading-[19.49px] text-white underline mt-4">
                    Know More
                </button> -----------
          <div className="md:mt-[-102px] relative w-full h-full flex items-end justify-end">
            <img
              src={Carrier1}
              alt=""
              className="mb-[-18px] w-[280px] h-[180px] max-w-[389.86px] max-h-[266px] lg:w-[375.86px] lg:h-[256px] object-contain"
            />
          </div>
        </div>

        <div className="p-4 bg-[#4B6AB0] flex flex-col items-start gap-4 md:gap-4 relative w-full md:w-[45%] md:h-[432px] rounded-[20px] flex-grow overflow-hidden">
          <h4
            style={{ color: "#FFFFFF" }}
            className="p-4 pb-0 text-[32px] md:text-[32px] font-bold leading-[32px] md:leading-[40px] text-center md:text-left"
          >
            Discover Your Next Job
          </h4>
          <p
            style={{ color: "#FFFFFF" }}
            className="pl-4 pr-4 text-[18px] font-normal leading-[24px] md:leading-[32px] text-start md:text-left"
          >
            Browse and apply for the latest job openings tailored to your career
            goals.
          </p>

          <div className="md:mt-[-120px] relative w-full h-full flex items-end justify-end">
            <img
              src={Carrier2}
              alt=""
              className="md:mb-[-15px] w-[250px] h-[120px] max-w-[353px] max-h-[200px] lg:w-[353px] lg:h-[180px] object-contain"
            />
          </div>
        </div>
      </div>
      <div className="mt-1 mx-4 md:mx-6 lg:mx-9 p-6 flex flex-col md:flex-row md:items-stretch gap-6 lg:gap-8">
        <div className="p-4 pb-0 bg-[#84D696] flex flex-col items-start gap-4 md:gap-4 relative w-full md:w-[45%] md:h-[432px] rounded-[20px] flex-grow overflow-hidden">
          <h4
            style={{ color: "#FFFFFF" }}
            className="p-4 pb-0 text-[32px] md:text-[32px] font-bold leading-[32px] md:leading-[40px] text-center md:text-left"
          >
            Craft Your Perfect Resume
          </h4>
          <p
            style={{ color: "#FFFFFF" }}
            className="pl-4 pr-4 text-[18px] font-normal leading-[24px] md:leading-[32px] text-start md:text-left"
          >
            Create a standout resume with our easy-to-use builder and get
            noticed by employers.
          </p>

          <div className="md:mt-[-120px] relative w-full h-full flex items-end justify-end">
            <img
              src={Carrier3}
              alt=""
              className=" lg:mb-[-17px] lg:mr-[-98px] md:mb-[-17px] md:mr-[-104px] sm:mb-[-10px] md:w-[320px] w-[250px] h-[120px] max-w-[353px] max-h-[200px] lg:w-[353px] lg:h-[180px] object-contain"
            />
          </div>
        </div>

        <div className="p-4 bg-[#FFA57A] flex flex-col items-start gap-4 md:gap-4 relative w-full md:w-[55%] md:h-[432px] rounded-[20px] flex-grow overflow-hidden">
          <h4
            style={{ color: "#FFFFFF" }}
            className="p-4 pb-0  text-[32px] md:text-[32px] font-bold leading-[32px] md:leading-[40px] text-center md:text-left"
          >
            Join Career Events
          </h4>
          <p
            style={{ color: "#FFFFFF" }}
            className="pl-4 pr-4 text-[18px] md:text-[18px] font-normal leading-[24px] md:leading-[32px] text-start md:text-left"
          >
            Connect with industry leaders and explore opportunities at upcoming
            career fairs and events.
          </p>

          <div className="md:mt-[-102px] relative w-full h-full flex items-end justify-end">
            <img
              src={Carrier4}
              alt=""
              className="lg:mb-[-45px] lg:mr-[-10px] md:mb-[-30px] md:mr-[-10px] sm:mb-[-10px] w-[280px] h-[180px] max-w-[389.86px] max-h-[266px] lg:w-[375.86px] lg:h-[256px] object-contain"
            />
          </div>
        </div>
      </div>  */}

      <div className="mt-9 mx-4 md:mx-6 lg:mx-6 p-6 pt-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-6 lg:gap-7 xl:gap-8">
        <div className="p-4 bg-[#FE5252] flex flex-col items-start gap-4 relative w-full h-auto md:h-[432px] lg:h-[432px] rounded-[20px] overflow-hidden pb-20">
          {" "}
          {/* Added pb-20 */}
          <h4 className="p-4 pb-0 text-[32px] below-sm:text-[18px] font-bold leading-[32px] md:leading-[40px] lg:leading-[40px] text-center md:text-left  lg:text-left text-white">
            Enhance Your Skills
          </h4>
          <p className="pl-4 pr-4 text-[18px] below-sm:text-[14px] font-normal leading-[24px] md:leading-[32px] lg:leading-[32px] text-start md:text-left lg:text-left text-white">
            Access a range of courses and resources to upskill and stay ahead in
            your field.
          </p>
          <div className="absolute bottom-0 right-0">
            <img
              src={Carrier1}
              alt=""
              className="w-[150px] h-[100px] md:w-[200px] md:h-[140px] lg:w-[210px] lg:h-[150px]"
            />
          </div>
        </div>

        <div className="p-4 bg-[#4B6AB0] flex flex-col items-start gap-4 relative w-full h-auto md:h-[432px] lg:h-[432px] rounded-[20px] overflow-hidden pb-20">
          <h4 className="p-4 pb-0 text-[32px] below-sm:text-[18px] font-bold leading-[32px] md:leading-[40px] lg:leading-[40px] text-center md:text-left lg:text-left text-white">
            Discover Your Next Job
          </h4>
          <p className="pl-4 pr-4 text-[18px] below-sm:text-[14px] font-normal leading-[24px] md:leading-[32px] lg:leading-[32px] text-start md:text-left md:text-left text-white">
            Browse and apply for the latest job openings tailored to your career
            goals.
          </p>
          <div className="absolute bottom-0 right-0">
            <img
              src={Carrier2}
              alt=""
              className="w-[150px] h-[100px] md:w-[200px] md:h-[140px] lg:w-[210px] lg:h-[150px] "
            />
          </div>
        </div>

        <div className="p-4 bg-[#84D696] flex flex-col items-start gap-4 relative w-full h-auto md:h-[432px] lg:h-[432px] rounded-[20px] overflow-hidden pb-20">
          <h4 className="p-4 pb-0 text-[32px] below-sm:text-[18px] font-bold leading-[32px] md:leading-[40px] lg:leading-[40px] xl:leading-[36px] text-center md:text-left lg:text-left text-white">
            Craft Your Perfect Resume
          </h4>
          <p className="pl-4 pr-4 text-[18px] below-sm:text-[14px] font-normal leading-[24px] md:leading-[32px] lg:leading-[32px] xl:leading-[30px] text-start md:text-left lg:text-left text-white">
            Create a standout resume with our easy-to-use builder and get
            noticed by employers.
          </p>
          <div className="absolute bottom-0 right-0">
            <img
              src={Carrier3}
              alt=""
              className="w-[150px] h-[100px] md:w-[200px] md:h-[140px] lg:w-[210px] lg:h-[150px]"
            />
          </div>
        </div>

        <div className="p-4 bg-[#FFA57A] flex flex-col items-start gap-4 relative w-full h-auto md:h-[432px] lg:h-[432px] rounded-[20px] overflow-hidden pb-20">
          <h4 className="p-4 pb-0 text-[32px] below-sm:text-[18px] font-bold leading-[32px] md:leading-[40px] lg:leading-[40px] text-center md:text-left lg:text-left text-white">
            Join Career Events
          </h4>
          <p className="pl-4 pr-4 text-[18px] below-sm:text-[14px] font-normal leading-[24px] md:leading-[32px] lg:leading-[32px] text-start md:text-left lg:text-left text-white">
            Connect with industry leaders and explore opportunities at upcoming
            career fairs and events.
          </p>
          <div className="absolute bottom-0 right-0">
            <img
              src={Carrier4}
              alt=""
              className="w-[150px] h-[100px] md:w-[200px] md:h-[140px] lg:w-[210px] lg:h-[150px]"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CarrierPotentials;
