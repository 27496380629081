import React, { useEffect, useState } from "react";
import "./Slider.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo1 from "../Assets/images/logo1.png";
import logo2 from "../Assets/images/logo2.png";
import logo3 from "../Assets/images/logo3.png";
import logo4 from "../Assets/images/logo4.png";
import rating from "../Assets/images/Rating.png";
import axios from "axios";
import { Tooltip } from "antd";

const comData = [
  {
    img: logo1,
    name: `Accenture`,
    ratings: `4.5`,
    reviews: `366 reviews`,
    details: `Accenture is a global professional services company with leading capabilities in digital, cloud a...`,
  },

  {
    img: logo2,
    name: `Deloitte`,
    ratings: `4.5`,
    reviews: `366 reviews`,
    details: `Deloitte is a global professional services company with leading capabilities in digital, cloud a...`,
  },

  {
    img: logo3,
    name: `Microsoft`,
    ratings: `4.5`,
    reviews: `366 reviews`,
    details: `Microsoft is a global professional services company with leading capabilities in digital, cloud a...`,
  },

  {
    img: logo4,
    name: `Accenture`,
    ratings: `4.5`,
    reviews: `366 reviews`,
    details:
      "Accenture is a global professional services company with leading capabilities in digital, cloud a...",
  },
];
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const Companies = () => {
  const [comp, setComp] = useState([]);
  const [more, setMore] = useState(false);
  const [id, setId] = useState(null);
  function stripHtmlTags(html) {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: (
      <div className="slick-prev">
        <svg
          width="15"
          height="26"
          viewBox="0 0 15 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.67568 13L15 3.03333L12.1622 0L0 13L12.1622 26L15 22.9667L5.67568 13Z"
            fill="#192C56"
          />
        </svg>
      </div>
    ),
    nextArrow: (
      <div className="slick-next">
        <svg
          width="15"
          height="27"
          viewBox="0 0 15 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.32432 13.5L0 3.53333L2.83784 0.5L15 13.5L2.83784 26.5L0 23.4667L9.32432 13.5Z"
            fill="#192C56"
          />
        </svg>
      </div>
    ),
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const fetchComps = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/organisationdata?type=detail&limit=10&offset=0&search_data=&onboarded=1`
      )
      .then((res) => {
        setComp(
          res.data.Organisation_Detail.filter(
            (el, i) =>
              el.org_logo !== null &&
              el.org_logo !== "" &&
              el.company_info !== "" &&
              el.company_info !== null &&
              el.org_name !== "pasio"
          )
        );
        console.log(comp, "cc12");
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchComps();
  }, []);
  return (
    <div>
      <div className=" w-full h-536 gap-36">
        <div className="mt-12 mb-10 px-2 font-bold text-[40px] text-center">
          Find Your Ideal Role at Top Companies
        </div>
        <div className="w-full">
  <div className="w-full px-7 flex justify-center">
    <div className="w-full text-center px-[15px]">
      <Slider {...settings}>
        {comp.map((d, index) => (
          <div className="h-[306px] bg-white border border-gray-200 rounded-lg shadow gap-3" key={index}>
            <div className="p-4 flex flex-col gap-2">
              <div className="flex justify-center">
                <img
                  src={d.org_logo}
                  alt=""
                  className="w-[100%] h-[100px] rounded-t-md object-contain"
                />
              </div>

              <div className="">
                <h5 className="mb-2 text-2xl text-black-800 font-bold tracking-tight text-gray-900">
                  {d.org_name}
                </h5>
                {/* <div className="flex items-center justify-center space-x-2">
                  <img src={rating} alt="rating" className="w-4 h-4" />
                  <p className="mb-1 font-normal text-black">
                    {d.ratings} | {d.reviews}
                  </p>
                </div> */}
                <p className="mb-1 font-normal text-black-700">
                  {d.company_info}
                  {/* {d.company_info.length > 80 ? (
                    <div>
                      {more && id === index ? (
                        <span>{d.company_info}</span>
                      ) : (
                        <span>
                          {d.company_info.substring(0, 80) + ".... "}
                        </span>
                      )}
                      {more && id === index ? (
                        <p
                          className="text-blue-500 underline cursor-pointer"
                          onClick={() => {
                            setMore(false);
                            setId(null);
                          }}
                        >
                          view less
                        </p>
                      ) : (
                        <span
                          className="text-blue-500 underline cursor-pointer"
                          onClick={() => {
                            setMore(true);
                            setId(index);
                          }}
                        >
                          view more
                        </span>
                      )}
                    </div>
                  ) : (
                    <p>{d.company_info}</p>
                  )} */}
                </p>

                {/* <button className="w-[177px] h-[51px] mb-3 text-white p-[16px_8px] gap-[10px] rounded-[6px] bg-[#192C56]">
                  View Job
                </button> */}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  </div>
</div>

        <div className="pt-10">
          {/* <button className="w-[198px] h-[51px] px-[48px] py-[16px] gap-[10px] rounded-[6px] bg-[#F26622]">
            View All Slides
          </button> */}
        </div>
      </div>
    </div>
  );
};
export default Companies;


{/* {d.company_info ? (
                            stripHtmlTags(d?.company_info)?.length > 80 ? (
                              <Tooltip title={stripHtmlTags(d.company_info)}>
                                <p className="my-2 font-normal text-black-700 mb-0">
                                  {stripHtmlTags(d.company_info).substring(
                                    0,
                                    80
                                  ) + "..."}
                                </p>
                              </Tooltip>
                            ) : (
                              <p className="my-2 font-normal text-black-700 mb-0">
                                {stripHtmlTags(d.company_info)}
                              </p>
                            )
                          ) : (
                            ""
                          )} */}
                        

                        {/* <button className="w-[177px] h-[51px] mb-3 text-white p-[16px_8px] gap-[10px] rounded-[6px]  bg-[#192C56]">
                          View Job
                        </button> */}