import React, { useState } from "react";
import FieldForceImg from "../Assets/FieldForce/FieldForceImg.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import Nav from "./Nav.js";
import Navbar from "../Pages/Navbar.js";
import Footer from "../Pages/Footer.js";

import bulb from "../Assets/FieldForce/Blub.png";
import growth from "../Assets/FieldForce/Growth.png";
import meetups from "../Assets/FieldForce/MeetUps.png";
import community from "../Assets/FieldForce/Community.png";

import offerperson from "../Assets/FieldForce/offerPerson.png";
import offergrowth from "../Assets/FieldForce/offerGrowth.png";
import offershield from "../Assets/FieldForce/offerShield.png";
import offercommunity from "../Assets/FieldForce/offerCommunity.png";

const FieldForce = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleOptionClick = (value) => {
    setSelectedValue(value);
    setDropdownOpen(false);
  };

  const options = [
    "Social Media",
    "Search Engines",
    "Word of Mouth",
    "Advertising",
    "Events",
    "Email Newsletter",
    "Blogs/Articles",
    "Other",
  ];

  const [PhoneNumber, setPhoneNumber] = useState("+91");
  // const [valid, setValid] = useState(true);

  const handleChange = (value) => {
    setPhoneNumber(value);
    // setValid(validatePhoneNumber(value));
  };

  // const validatePhoneNumber = (phoneNumber) =>
  // {
  //     const phoneNumberPattern = /^\d{10}$/;
  //     return phoneNumberPattern.test(phoneNumber)
  // }

  const offers = [
    {
      id: 1,
      title: "Support for Your Rights",
      description:
        "We are here to listen to your concerns and help protect your rights at work.",
      image: offershield,
      color: "border-orange-500 bg-orange-100",
      badgeColor: "bg-orange-500",
    },
    {
      id: 2,
      title: "Career Guidance and Growth",
      description:
        "Learn new skills and get help with finding better opportunities. We will support your growth every step of the way.",
      image: offergrowth,
      color: "border-blue-700 bg-blue-100",
      badgeColor: "bg-blue-700",
    },
    {
      id: 3,
      title: "Monthly Meetups",
      description:
        "Join our virtual meetups to talk with other workers and learn from experts in the field.",
      image: offerperson,
      color: "border-orange-400 bg-orange-100",
      badgeColor: "bg-orange-400",
    },
    {
      id: 4,
      title: "A Caring Community",
      description:
        "You are not alone. Connect with others who face similar challenges and be part of a group that supports each other.",
      image: offercommunity,
      color: "border-blue-700 bg-blue-100",
      badgeColor: "bg-blue-700",
    },
  ];

  const [successMessage, setSuccessMessage] = useState("");

  const [name, setName] = useState("");
  // const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState("");
  const [typeOfWorker, setTypeOfWorker] = useState("");
  const [selectedValue, setSelectedValue] = useState(
    "How did you hear about us?"
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      name,
      mobile: PhoneNumber,
      email,
      type_of_worker: typeOfWorker,
      about_us: selectedValue,
    };

    try {
      const response = await axios.post(
        "https://samarth.prod.api.sapioglobal.com/form/saksham/field-form",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        setSuccessMessage("Your response has been successfully submitted!");

        setName("");
        setPhoneNumber("+91");
        setEmail("");
        setTypeOfWorker("");
        setSelectedValue("How did you hear about us?");
      } else {
        setSuccessMessage("There was an error with your submission.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSuccessMessage("There was an error with your submission.");
    }
  };

  return (
    <div>
      <Nav />
      <Navbar />
      <div className="mt-10 flex  min-h-screen justify-center items-center">
        <div className="flex flex-col md:flex-row md:space-x-6 md:space-y-0 space-y-6  max-w-4xl rounded-xl md:rounded-tr-sm md:rounded-br-sm shadow-lg bg-[#0E2A59]">
          <div className="w-full md:w-[65%]flex flex-col p-8 md:pr-0 justify-between">
            <div>
              <p className="text-white font-inter text-[32px] font-semibold leading-[28.4px] text-left md:pb-6">
                Join the Field Workforce Association in India
              </p>
              <p className="text-white font-inter text-[17px] font-normal leading-[20.4px] text-left md:pb-10">
                Saksham, under the Government of India, invites all field
                workers to join the Field Workforce Association today. Whether
                you work in sales, logistics, services, or any other field-based
                role, we are here to support you and build a community that
                cares for your needs and helps you grow.
              </p>
            </div>
            <div>
              <img src={FieldForceImg}></img>
            </div>
          </div>
          <div>
            <div className="w-full md:w-[90%] self-end inline-block md:mr-[-50px] bg-[#FCFDF2] rounded-xl md:rounded-tl-3xl md:rounded-bl-3xl  md:rounded-tr-sm md:rounded-br-sm  shadow-lg p-6">
              <div>
                <p className="font-inter text-[32px] font-semibold leading-[28.4px] text-left md:mb-[25px]">
                  Interest Form
                </p>
                <p className="font-inter text-[15px] font-normal leading-[18px] text-left md:mb-[25px]">
                  Fill out the form below to become a part of our association.
                  We’ll guide you on how to get started and provide the support
                  you need in your field-based work.
                </p>
              </div>
              {successMessage && (
                <div className="text-[#0FFF50] text-[17px] text-center p-2 mb-2 rounded">
                  {successMessage}
                </div>
              )}

              <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
                <div className="pl-5 pr-5">
                  <div className="text-left">
                    <div className="mt-3 mb-3">
                      <label for="" className="text-[15px] font-normal ">
                        {" "}
                        Name<span className="text-red-700">*</span>
                      </label>
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Your Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        className="text-[15px] ring-1 ring-[#818181] bg-[#FCFDF2] w-full rounded-[7px] px-4 py-3 outline-none focus:ring-2 focus:ring-black-300"
                      ></input>
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="mt-4 mb-3">
                      <div className="">
                        <label className="">
                          Phone Number <span className="text-red-700">*</span>
                          <div className="mt-3 text-[15px] font-inter ring-1 ring-[#818181] bg-[#FCFDF2] outline-none focus:ring-2 focus:ring-black-300 items-center w-full rounded-[7px] box-border">
                            <PhoneInput
                              className="w-full"
                              country={"India"}
                              value={PhoneNumber}
                              onChange={handleChange}
                              inputProps={{
                                required: true,
                                style: { width: "full" },
                              }}
                            />
                          </div>
                        </label>

                        {/* {!valid && <p className=''>Please Enter a Valid phone number.</p>} */}
                      </div>
                    </div>
                  </div>

                  <div className="text-left">
                    <div className="mt-4 mb-3">
                      <label for="" className="text-[15px] font-normal ">
                        Email
                      </label>
                    </div>
                    <div>
                      <input
                        placeholder="Enter Your Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="text-[15px] ring-1 ring-[#818181] bg-[#FCFDF2] w-full rounded-[7px] px-4 py-3 outline-none focus:ring-2 focus:ring-black-300"
                      ></input>
                    </div>
                  </div>

                  <div className="text-left">
                    <div className="mt-4 mb-3">
                      <label for="" className="text-[15px] font-normal ">
                        {" "}
                        Type of Worker <span className="text-red-700">
                          *
                        </span>{" "}
                      </label>
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder=""
                        value={typeOfWorker}
                        onChange={(e) => setTypeOfWorker(e.target.value)}
                        required
                        className="text-[15px] ring-1 ring-[#818181] bg-[#FCFDF2] w-full rounded-[7px] px-4 py-3 outline-none focus:ring-2 focus:ring-black-300"
                      ></input>
                    </div>
                  </div>
                  <div>
                    <div className="mt-5 relative w-full">
                      <button
                        type="button"
                        className="mt-8 flex justify-between text-center  bg-[#FCFDF2] text-[#000000] text-[15px] ring-1 ring-[#818181] w-full rounded-[7px] px-4 py-3 outline-none focus:ring-2 focus:ring-black-300"
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                      >
                        {selectedValue}
                        <svg
                          className="w-[13px] h-[13px] text-center"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 4 4 4-4"
                          />
                        </svg>
                      </button>
                      {dropdownOpen && (
                        <div className="absolute left-0 mt-2 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-full">
                          <ul className="py-2 text-sm text-left text-[#000000]">
                            {options.map((option) => (
                              <li key={option}>
                                <a
                                  // href="#"
                                  className="block px-4 py-2 hover:bg-gray-100"
                                  onClick={() => handleOptionClick(option)}
                                >
                                  {option}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}{" "}
                    </div>

                    <button
                      onClick={handleSubmit}
                      type="submit"
                      className="inline-block self-center bg-[#0E2A59] text-white font-semibold rounded-lg text-center mt-6 px-6 py-3"
                    >
                      {" "}
                      Submit
                    </button>
                  </div>
                </div>
              </form>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:h-[400px] h-[1400px]">
        {/* className={` w-full sm:w-[95%] md:[95%] lg:w-[95%] h-[350px] border-2 bg-[#ffffff] rounded-xl p-6 shadow-md flex flex-col items-center text-center`}  */}
        <section className="pt-15 pb-7">
          <h2 className="text-center text-[32px] font-bold mt-14 mb-10">
            What We Offer
          </h2>
          <div className="flex flex-wrap justify-center sm:align gap-8 pl-4 pr-4 md:pl-24 md:pr-24 mt-7">
            {offers.map((offer) => (
              <div key={offer.id} className="h-[300px]">
                <div
                  className={`flex justify-center w-[274px] h-[150px] md:h-[170px] ${
                    offer.id === 1
                      ? "bg-[#F78D24]"
                      : offer.id === 2
                      ? "bg-[#0E2A59]"
                      : offer.id === 3
                      ? "bg-[#FD8D67]"
                      : "bg-[#0E2A59]"
                  } pt-[20px] rounded-[30px]`}
                >
                  <div
                    className={`relative w-[249px]  h-[285px] bg-[#ffffff] rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl rounded-br-3xl px-3 pt-7 shadow-md flex flex-col items-center text-center`}
                  >
                    {/* below-sm:w-[75%] sm:w-[68%] md:w-[32%] lg:w-[20%] */}
                    {/* Shadow from Top-Left */}
                    {/* <div
                  className={`absolute -top-0 -left-0 -right-0 w-full h-full rounded-3xl pointer-events-none`}
                  style={{
                    boxShadow: `-8px -8px 8px 5px ${offer.id % 2 === 0 ? "#0E2A59" : "#F78D24"
                      }`,
                  }}
                ></div> */}
                    {/* <div className="w-16 h-8 bg-[#F78D24]"></div> */}
                    <div className="flex flex-col justify-center items-center">
                      <div
                        className={`w-16 h-16 flex items-center justify-center rounded-full ${offer.color} mb-4`}
                      >
                        <img
                          src={offer.image}
                          alt={offer.title}
                          className="w-10 h-10 p-1"
                        />
                      </div>

                      <div className="flex flex-col justify-between space-y-2 ">
                        <div className="md:text-left pl-4">
                          <h3 className="font-semibold text-[#000000] text-lg mb-2">
                            {offer.title}
                          </h3>
                          <p className="text-sm text-[#000000] mb-6">
                            {offer.description}
                          </p>
                        </div>

                        <div
                          className={`mt-2 absolute bottom-0 left-1/2 transform -translate-x-1/2 w-16 h-8 ${offer.badgeColor} text-white flex items-center justify-center `}
                          style={{
                            borderTopLeftRadius: "50px",
                            borderTopRightRadius: "50px",
                          }}
                        >
                          {`0${offer.id}`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>

      <section className="pt-12 pb-10">
        <h2 className="text-center text-[32px] font-bold mb-8">
          Why Join the Field Force Workers Association?
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-1 pl-4 pr-4 md:pl-40 md:pr-40">
          <div className="bg-[#FF706F] text-white pl-4 pr-4 pt-10 pb-10 rounded-lg shadow-lg flex flex-col items-center justify-center">
            <img src={bulb} />
            <h3 className="text-[15px] font-semibold pt-4 ">
              Help with Rights and Grievances
            </h3>
          </div>
          <div className="bg-[#955E88] text-white pl-4 pr-4 pt-10 pb-10 rounded-lg shadow-lg flex flex-col items-center justify-center">
            <img src={growth} />
            <h3 className="text-[15px] font-semibold pt-4">
              Guidance to Grow Your Career
            </h3>
          </div>
          <div className="bg-[#5F406A] text-white pl-4 pr-4 pt-10 pb-10 rounded-lg shadow-lg flex flex-col items-center justify-center">
            <img src={meetups} />
            <h3 className="text-[15px] font-semibold pt-4">
              Regular Meetups to Share and Learn
            </h3>
          </div>
          <div className="bg-[#4E5D7E] text-white pl-4 pr-4 pt-10 pb-10 rounded-lg shadow-lg flex flex-col items-center justify-center">
            <img src={community} />
            <h3 className="text-[15px] font-semibold pt-4">
              A Strong Community that Cares
            </h3>
          </div>
        </div>

        {/* Contact Information */}
        {/* <p className="text-center text-[#000000] mt-8">
          For more information or to understand how we can support you better, please call us on <a href="tel:+917400052852" className="text-blue-500">+91 74000 52852</a> or email us at <a href="mailto:info@sakshantaffic.org" className="text-blue-500">info@sakshantaffic.org</a>.
        </p> */}
      </section>
      <Footer />
    </div>
  );
};

export default FieldForce;
