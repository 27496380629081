import React from "react";
import Job1 from "../Assets/images/Job1.png";
import Carrier2 from "../Assets/images/Carrier2.png";

const Carrier = () => {
  return (
    <div className="my-11 px-4 md:px-8 lg:px-12 p-3  flex flex-col md:flex-row  md:justify-between md:gap-6 lg:gap-8 items-stretch">
      <div className="p-4 flex flex-col items-start md:items-start gap-2 md:gap-2 relative w-full md:w-[calc(50%-1rem)] mt-6 md:mt-0 rounded-[20px] bg-[#AAC9F8] flex-grow">
        <h4
          style={{ color: "#192C56" }}
          className="p-4 pb-0 text-start text-[32px] below-sm:text-[20px] font-bold leading-[32px] md:leading-[36px] md:text-left"
        >
          Assess Your Job Readiness
        </h4>
        <p
          style={{ color: "#192C56" }}
          className="pl-4 pr-4 text-[18px] below-sm:text-[14px] font-normal leading-[28px] text-left md:text-left"
        >
          Evaluate your skills, experience, and qualifications with our
          comprehensive Job Readiness Score. Get personalised insights and tips
          to improve your employability and stand out to potential employers.
        </p>

        <div className="relative w-full h-full flex-grow">
          <div className="w-full flex justify-end items-end h-full">
            <img
              src={Job1}
              alt="Ace Your Interviews"
              className="w-[342.36px] h-[169.49px] md:w-[352.36px] md:h-[179.49px] below-sm:w-[240px] object-contain"
              style={{ position: "relative", bottom: "0", right: "-7%" }}
            />
          </div>
        </div>
      </div>

      <div className="p-4 flex flex-col items-start md:items-start gap-2 md:gap-2 relative w-full md:w-[calc(50%-1rem)] mt-6 md:mt-0 rounded-[20px] bg-[#AAC9F8] flex-grow">
        <h4
          style={{ color: "#192C56" }}
          className="p-4 pb-0 text-start text-[32px] below-sm:text-[20px] font-bold leading-[32px] md:leading-[36px] md:text-left"
        >
          Ace Your Interviews with Expert Preparation
        </h4>
        <p
          style={{ color: "#192C56" }}
          className="pl-4 pr-4 text-[18px] below-sm:text-[14px] font-normal leading-[28px] text-left md:text-left"
        >
          Prepare for success with our tailored mock interviews and interview
          coaching. Practice real-world scenarios, receive feedback, and refine
          your responses to boost your confidence and performance in any job
          interview.
          <span style={{ lineHeight: "normal", display: "inline" }}> </span>
        </p>

        <div className="relative w-full h-full flex-grow">
          <div className="w-full flex justify-end items-end h-full">
            <img
              src={Carrier2}
              alt="Ace Your Interviews"
              className="w-[342.36px] h-[169.49px] md:w-[342.36px] md:h-[169.49px] below-sm:w-[240px] object-contain"
              style={{ position: "relative", bottom: "0", right: "-3%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carrier;
