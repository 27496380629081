import React from "react";

const Policy = () => {
  return (
    <div>
      <div className="py-5">
        <h1 className="text-3xl font-bold">Privacy Policy</h1>
        <div className="w-full flex justify-center">
          <div className="w-[70%] text-left">
            <h5 className="text-lg font-semibold mt-5">
              Last Updated: 26 March 2024
            </h5>
            <p className="text-justify">
              Welcome to Saksham. Your privacy and trust are important to us.
              This Privacy Policy explains how we collect, use, disclose, and
              safeguard your information when you visit our website, including
              any other media form, media channel, mobile website, or mobile
              application related or connected thereto (collectively, the
              “Site”). Please read this privacy policy carefully. If you do not
              agree with the terms of this privacy policy, please do not access
              the site.
            </p>
            <h5 className="text-lg font-semibold mt-5">
              Collection of Your Information
            </h5>
            <p className="text-justify">
              We may collect information about you in a variety of ways. The
              information we may collect on the Site includes:
            </p>
            <p className="text-justify">
              Personal Data: Personally identifiable information, such as your
              name, shipping address, email address, and telephone number, and
              demographic information, such as your age, gender, hometown, and
              interests, that you voluntarily give to us when you register with
              the Site or when you choose to participate in various activities
              related to the Site.
            </p>
            <p className="text-justify">
              Derivative Data: Information our servers automatically collect
              when you access the Site, such as your IP address, browser type,
              operating system, access times, and the pages you have viewed
              directly before and after accessing the Site.
            </p>
            <p className="text-justify">
              Financial Data: Financial information, such as data related to
              your payment method (e.g. valid credit card number, card brand,
              expiration date) that we may collect when you purchase, order,
              return, exchange, or request information about our services from
              the Site.
            </p>
            <h5 className="text-lg font-semibold mt-5">
              Use of Your Information
            </h5>
            <p className="text-justify">
              Accurate information about you lets us provide you with a smooth,
              efficient, and customized experience. Specifically, we may use
              information collected about you via the Site to:
            </p>
            <p className="text-justify">
              Create and manage your account.
              <br /> Email you regarding your account or the latest relevant
              updates. .<br />
              Increase the efficiency and operation of the Site..
              <br /> Monitor and analyse usage and trends to improve your
              experience with the Site.
            </p>
            <h5 className="text-lg font-semibold mt-5">
              Disclosure of Your Information
            </h5>
            <p className="text-justify">
              We may share information we have collected about you in certain
              situations. Your information may be disclosed as follows:
            </p>
            <p className="text-justify">
              By Law or to Protect Rights: If we believe the release of
              information about you is necessary to respond to legal process, to
              investigate or remedy potential violations of our policies, or to
              protect the rights, property, and safety of others, we may share
              your information as permitted or required by any applicable law,
              rule, or regulation. Security of Your Information
              <br />
              We use administrative, technical, and physical security measures
              to help protect your personal information. While we have taken
              reasonable steps to secure the personal information you provide to
              us, please be aware that despite our efforts, no security measures
              are perfect or impenetrable, and no method of data transmission
              can be guaranteed against any interception or other type of
              misuse.
            </p>
            <h5 className="text-lg font-semibold mt-5">Contact Us</h5>
            <p className="text-justify">
              If you have questions or comments about this Privacy Policy,
              please contact us at:
            </p>
            <p className="text-justify">
              Email: dayal@sakshamtifac.org
              <br />
              Phone: +91 77118 58111
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Policy;
