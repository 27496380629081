import React from "react";
import Logo from "../Assets/images/Image1.png";
import E_logo from "../Assets/images/Email_L.png";
import Fb_logo from "../Assets/images/Fb_logo.png";
import X_logo from "../Assets/images/X_logo.png";
import YT_logo from "../Assets/images/YT_logo.png";
import IG_logo from "../Assets/images/IG_logo.png";
import LI_logo from "../Assets/images/LI_logo.png";
import FooterBG from "../Assets/images/FooterBG.png";
import { Link } from "react-router-dom";
import SakshamLogo from "../Assets/images/Saksham_Logo.svg";

function Footer() {
  // const navigate = useNavigate();
  return (
    <footer
      className="w-full shadow p-5 pb-4 relative"
      style={{
        backgroundImage: `url(${FooterBG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
        <div className="w-full max-w-screen-xl mx-auto pt-10">
        <div className="w-full flex justify-center items-center max-w-screen-xl mx-auto pt-10 sm:pt-7">
      <img src={SakshamLogo} alt="Logo" className="w-[162px] h-[137px] below-sm:w-[142px] below-sm:h-[120px]" />
    </div>
          
        </div>

        <div className="flex flex-col w-full max-w-screen-xl gap-6 px-4 pb-4 pt-12 sm:pt-9 lg:gap-7 md:gap-7">
          <div className="flex items-start justify-between ">
          <div className="mt-5">
            <p className="text-center text-[18px] below-sm:text-[14px] leading-[17px] font-normal text-[#FFFFFF]">
              An apex mission to end unemployment in India. An initiative by
              TIFAC, an autonomous organisation under the Government of India
              and implemented by Sapio Analytics.
            </p>
          </div>
            {/* <div className="w-full max-w-xs">
              <h2 className="mb-8 text-[18px] font-semibold text-[#FFFFFF]">
                Quick Links
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium cursor-pointer">
                <li className="mb-3 text-white font-normal">
                  <a href="" className="hover:underline">
                    Skills
                  </a>
                </li>
                <li className="mb-3 text-white font-normal">
                  <a href="" className="hover:underline">
                    Jobs
                  </a>
                </li>
                <li className="mb-3 text-white font-normal">
                  <a href="" className="hover:underline">
                    Job Fair
                  </a>
                </li>
                <li className="mb-3 text-white font-normal">
                  <a href="" className="hover:underline">
                    About Us
                  </a>
                </li>
              </ul>
            </div>

            <div className="flex items-center">
              <svg
                width="1"
                height="167"
                viewBox="0 0 1 157"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line x1="0.5" y1="0" x2="0.500007" y2="157" stroke="white" />
              </svg>
            </div>

            <div className="w-full max-w-xs">
              <h2 className="mb-8 text-[18px] font-semibold text-[#FFFFFF]">
                Useful Links
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-3 text-white font-normal">
                  <a href="" className="hover:underline">
                    Home
                  </a>
                </li>
                <li className="mb-3 text-white font-normal">
                  <a href="" className="hover:underline">
                    Success Stories
                  </a>
                </li>
                <li className="mb-3 text-white font-normal">
                  <Link
                    // to="/Privacy-Policy-Saksham.pdf"
                    target="_blank"
                    to="/privacypolicy"
                    // download
                  >
                    <p className="hover:underline">Privacy Policy</p>
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>

          {/* Paragraph Below the Grid Boxes */}
          <p className="font-normal flex text-center text-[10px] text-[#FFFFFF] mt-5">
            Disclaimer: Shramik and industry information on this portal will
            only be used to increase employment opportunities and for Govt.
            policy formation regarding skill assessment, gaps, needs, and
            training through data analytics. Only workers/industries who give
            consent will have information shared.
          </p>
        </div>

        <div className="w-full flex flex-col md:items-start items-center  md:pl-20 md:pt-10 ">
          {/* <h5 className="text-[18px] text-[#FFFFFF] font-semibold mb-3">
            Subscribe Now
          </h5>
          <p className="text-[#FFFFFF] text-[16px] leading-[17px] font-normal mb-5">
            Don’t miss our future updates! Get Subscribed Today!
          </p>
          <form className="flex flex-col lg:flex-row items-start sm:items-center below-sm:items-center gap-2 w-full">
            <input
              type="email"
              placeholder="Enter your email here"
              className="lg:w-[280px] sm:[200px] h-[36px] sm:w-auto px-4 py-2 text-black rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <button
              type="submit"
              className="bg-white rounded-[5px] w-[36px] h-[36px] flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <img src={E_logo} alt="Submit" className="w-[24px] h-[24px]" />
            </button>
          </form> */}
          <p className="text-white font-semibold text-[18px] below-sm:text-[14px] py-6 below-sm:py-4 underline">
            Connect with us
          </p>
          <ul className="flex gap-3">
            <li>
              <a
                href="https://www.linkedin.com/company/sakshamtifac/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Fb_logo} alt="LinkedIn" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/sakshamtifac/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={X_logo} alt="Instagram" />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/people/Saksham/100068920491625/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={LI_logo} alt="Facebook" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <p className="pt-2 below-sm:pt-5 flex text-center text-white justify-center font-normal text-[12px]">
        ©2024. Saksham by TIFAC. All Rights Reserved.
      </p>
    </footer>
  );
}

export default Footer;
