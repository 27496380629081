import React from "react";
// import JFE1 from "../Assets/images/JFE1.png";
// import JFE2 from "../Assets/images/JFE2.png";
// import JFE3 from "../Assets/images/JFE3.png";
// import JFE4 from "../Assets/images/JFE4.png";

import JFS5 from "../Assets/images/JFS5.png";
import JFS6 from "../Assets/images/JFS6.png";

import JFS7 from "../Assets/images/JFS7.png";

import JFS8 from "../Assets/images/JFS8.png";

import JFS11 from "../Assets/images/JFS11.png";

import JFS12 from "../Assets/images/JFS12.png";

const JobFairSec = () => {
  return (
    <div className="w-full mt-5 px-12 p-2 flex flex-col items-center lg:flex-row lg:items-start lg:justify-between lg:gap-12">
      <div className="flex flex-col items-center lg:items-start gap-3 lg:gap-3">
        <h1 className="w-full max-w-lg text-center lg:text-left text-[40px] below-sm:text-[20px] below-sm:text-justify font-bold leading-[40px]">
          Job Fairs & Events
        </h1>
        <p className="w-full max-w-md sm:max-w-xl md:max-w-2xl  text-justify  text-[18px] below-sm:text-[14px] font-normal leading-[32px] text-[#192C56] mb-4 md:mb-8">
          Saksham regularly organises job fairs, career fairs, and career
          counselling sessions to connect job seekers with employers. These
          events provide direct interaction, job opportunities, and personalised
          career guidance to help individuals improve their skills and prepare
          for interviews. Stay informed about upcoming events and take the next
          step in your career with Saksham!
        </p>
        {/* <button className="flex justify-center items-center w-[110px] h-[51px] bg-[#F26622] rounded-[6px] text-[16px] font-bold leading-[19.49px] text-white m-4 ">
          Know more
        </button> */}
      </div>
      <div className="grid grid-cols-3 below-sm:grid-cols-2 gap-2 md:mt-4">
        <div>
          <img className="h-auto max-w-full rounded-lg" src={JFS5} alt="" />
        </div>
        <div>
          <img className="h-auto max-w-full rounded-lg" src={JFS11} alt="" />
        </div>
        <div>
          <img className="h-auto max-w-full rounded-lg" src={JFS7} alt="" />
        </div>
        <div>
          <img className="h-auto max-w-full rounded-lg" src={JFS8} alt="" />
        </div>
        <div>
          <img className="h-auto max-w-full rounded-lg" src={JFS6} alt="" />
        </div>
        <div>
          <img className="h-auto max-w-full rounded-lg" src={JFS12} alt="" />
        </div>
      </div>
    </div>
  );
};

export default JobFairSec;
