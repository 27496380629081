import "./App.css";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  HashRouter,
} from "react-router-dom";
import Homepage from "./Pages/Homepage";
import Jobs from "./Pages/Jobs";
import Policy from "./Pages/Policy";
import FieldForce from "./Components/FieldForce";
import Navbar from "./Pages/Navbar";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Homepage />}></Route>
        <Route path="/privacypolicy" element={<Policy />}></Route>
        <Route path="/fieldforce" element={<FieldForce />}></Route>
        {/* <Route path="/jobs" element={<Jobs />}></Route> */}
      </Routes>
      {/* <Route
        path="https://sakshamtifac.org/privacypolicy"
        element={<Policy />}
      ></Route> */}
    </div>
  );
}

export default App;
